
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Home = () => {
    const history = useHistory()
    return (
        <>
            <div>Home</div>
            <button onClick={() => history.push('/signin')} >SignIn</button>
        </>
    )
}

export default Home