import React, {memo} from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";

const UserProfile = ({ authUser }) => {
  // console.log(authUser)
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li onClick={() => logout()}>Logout</li>
    </ul>
  );

  const logout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={"https://via.placeholder.com/150"}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {/* {JSON.parse(localStorage.getItem("user")).fullName} */}
          {/* <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/> */}
        </span>
      </Popover>
    </div>
  );
};

export default memo(UserProfile);